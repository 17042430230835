.dropdown-container {
  position: relative;

  .d-flex {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 8px;
    cursor: pointer;

    img {
      width: 18px;
      height: 18px;
      object-fit: cover;
    }
  }

  .dropdown-list {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--background);

    > div {
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      background-color: transparent;

      &:hover {
        background-color: rgb(33, 46, 56);
      }

      img {
        width: 14px;
        height: 14px;
        object-fit: cover;
      }
    }
  }
}
